import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import definition from "../content/homepage/definition.json"
import { StaticImage } from "gatsby-plugin-image"

export const Definition = () => {
  return (
    <div className="base-section definition-section">
      <StaticImage
        className="definition-wave"
        src="../assets/img/layout/wave-15.svg"
        alt="wave"
      />
      <Container>
        <Row className="m-0 flex-column-reverse flex-lg-row">
          <Col className="my-auto mx-auto mx-lg-0" xs={12} md={10} lg={7}>
            <p className="title definition">{definition.title}</p>
            <p className="content">{definition.content.opening}</p>
            <ul className="content">
              {definition.content.list.map(c => {
                return <li>{c.content}</li>
              })}
            </ul>
          </Col>

          <Col className="my-auto">
            <StaticImage src="../assets/img/screenlogo.png" alt="screen logo" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
