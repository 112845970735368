import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import customers from "../content/homepage/customers.json"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Carousel from "react-multi-carousel"

const getImageFromData = (allImage, path) => {
  return getImage(
    allImage.filter(edge => edge.node.relativePath === path)[0].node
      .childImageSharp.gatsbyImageData
  )
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

export const Customers = ({ data }) => {
  const allImage = data.edges
  return (
    <div className="base-section customers-section">
      <Container>
        <Row>
          <Col className="text-center">
            <p className="title">{customers.title}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Carousel
              responsive={responsive}
              autoPlay={true}
              infinite={true}
              autoPlaySpeed={1200}
              removeArrowOnDeviceType={[
                "superLargeDesktop",
                "desktop",
                "tablet",
                "mobile",
              ]}
            >
              {customers.customers.map(c => {
                const image = getImageFromData(allImage, c.image)
                return (
                  <div className="text-center">
                    <GatsbyImage alt={c.alt} image={image} />
                  </div>
                )
              })}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
