import * as React from "react"
import { Card, Col, Row } from "react-bootstrap"
import values from "../content/homepage/values.json"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const getImageFromData = (allImage, path) => {
  return getImage(
    allImage.filter(edge => edge.node.relativePath === path)[0].node
      .childImageSharp.gatsbyImageData
  )
}

export const Values = ({ data }) => {
  const allImage = data.edges
  return (
    <div className="base-section values-section">
      <Row>
        <Col className="text-center">
          <p className="title">{values.title}</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {values.values.map(v => {
          const image = getImageFromData(allImage, v.image)
          return (
            <Col xs={9} sm={6} md={4} lg={2} className="mb-5">
              <Card className="value-card">
                <GatsbyImage image={image} alt={v.alt} />
                <div className="value text-center">{v.content}</div>
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
