import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import features from "../content/homepage/features.json"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const getImageFromData = (allImage, path) => {
  return getImage(
    allImage.filter(edge => edge.node.relativePath === path)[0].node
      .childImageSharp.gatsbyImageData
  )
}

export const Features = ({ data }) => {
  const allImage = data.edges
  return (
    <div className="base-section features-section">
      <StaticImage
        className="feature-wave top-wave"
        src="../assets/img/layout/wave-8.svg"
        alt="wave"
      />
      <StaticImage
        className="feature-wave bottom-wave"
        src="../assets/img/layout/wave-10.svg"
        alt="wave"
      />
      <Container>
        <Row>
          <Col className="text-center">
            <p className="title">{features.title}</p>
          </Col>
        </Row>
        {features.features.map(f => {
          const image = getImageFromData(allImage, f.image)
          return (
            <div className="mb-5">
              <Row className="mb-3">
                <Col className="text-center">
                  <p className="feature-title">{f.featureName}</p>
                </Col>
              </Row>

              <Row className={f.order}>
                <Col xs={12} lg={6} className="text-center mb-5 mb-lg-0">
                  <GatsbyImage image={image} alt="" />
                </Col>

                <Col className="my-auto feature-text">
                  {f.content.opening}
                  <ul>
                    {f.content.list.map(l => {
                      return <li>{l.content}</li>
                    })}
                  </ul>
                </Col>
              </Row>
            </div>
          )
        })}
      </Container>
    </div>
  )
}
