import * as React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import footer from "../content/footer.json"

export const Footer = () => {
  return (
    <div className="base-section footer">
      <StaticImage
        className="footer-wave left-wave"
        src="../assets/img/layout/polygon-3.svg"
        alt=""
      />
      <StaticImage
        className="footer-wave right-wave"
        src="../assets/img/layout/diagonal-shape-3.svg"
        alt=""
      />
      <Container>
        <Row className="mb-5">
          <Col xs={12} lg={8} className="text-center mb-3 mb-lg-0 trial-text">
            QUAN TÂM ĐẾN SMARTDOSE AI?
          </Col>
          <Col className="my-auto text-center">
            <Button
              className="trial-button"
              href={footer.trial}
              target="_blank"
              rel="noreferrer"
            >
              Đăng ký dùng thử
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} className="mb-5 mb-md-0">
            <StaticImage
              className="mb-4"
              src="../assets/img/sd-logos/logo-white.png"
              alt="logo"
            />
            <p>Số 70 Ngách 23 Ngõ 82 Chùa Láng, Đống Đa, Hà Nội</p>
            <p>1900 86 68 75 (bấm phím 2)</p>
            <p>contact@n2tp.com</p>
          </Col>

          <Col>
            <div className="text-center">
              <a
                href={footer.facebook}
                target="_blank"
                rel="noreferrer"
                className="mr-5"
              >
                <i className="fab fa-facebook-f sns" />
              </a>
              <a href={footer.linkedin} target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin-in sns" />
              </a>
            </div>
            <hr style={{ backgroundColor: "white" }} />

            <Row>
              <Col>
                <a className="link" href="#">
                  White paper
                </a>
              </Col>
              <Col className="text-center">
                <a className="link" href="#">
                  Brochure
                </a>
              </Col>
              <Col className="text-right">
                <a className="link" href="#">
                  SmartClinX
                </a>
              </Col>
            </Row>

            <hr style={{ backgroundColor: "white" }} />
            <div className="text-center">
              <Button className="smartclinx-button">SmartClinX</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
