import * as React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { getImage, StaticImage } from "gatsby-plugin-image"
import vancomycinCTA from "../content/homepage/vancomycinCTA.json"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

export const VancomycinCTA = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "layout/bg-overlay-16.png" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
    >
      <div className="base-section vancomycin-cta-section">
        <Container>
          <Row className="mb-5">
            <Col className="text-center">
              <StaticImage
                src="../assets/img/sd-logos/logo-color.png"
                alt="logo"
              />
            </Col>
          </Row>
          <Row className="mb-5">
            <Col className="text-center title my-auto vanco-cta-text">
              {vancomycinCTA.title}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={6} md={3} className="text-center">
              <Button
                href={vancomycinCTA.smartClinX.link}
                target="_blank"
                className="cta-button"
                rel="noreferrer"
              >
                {vancomycinCTA.smartClinX.title}
              </Button>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <Button
                href={vancomycinCTA.readMore.link}
                target="_blank"
                className="cta-button"
              >
                {vancomycinCTA.readMore.title}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </BackgroundImage>
  )
}
