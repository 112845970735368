import * as React from "react"
import { Layout } from "../layout"
import { Hero } from "../page-fragments/Hero"
import { Definition } from "../page-fragments/Definition"
import { Values } from "../page-fragments/Values"
import { Protocol } from "../page-fragments/Protocol"
import { VancomycinCTA } from "../page-fragments/VancomycinCTA"
import { Experts } from "../page-fragments/Experts"
import { Partners } from "../page-fragments/Partners"
import { Features } from "../page-fragments/Features"
import { Customers } from "../page-fragments/Customers"
import { graphql } from "gatsby"
import Seo from "../layout/seo"

// markup
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Trang Chủ" />
      <Hero />
      <Customers data={data.customers} />
      <Definition />
      <Values data={data.values} />
      <Protocol />
      <VancomycinCTA />
      <Features data={data.features} />
      <Experts data={data.experts} />
      <VancomycinCTA />
      <Partners data={data.partners} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    bg: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "layout" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    values: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "values" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(width: 400)
          }
        }
      }
    }
    experts: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "experts" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(width: 200)
          }
        }
      }
    }
    features: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "features" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(height: 500)
          }
        }
      }
    }
    partners: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "partners" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(height: 150)
          }
        }
      }
    }
    customers: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "hospitals-logos" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(height: 100)
          }
        }
      }
    }
  }
`
