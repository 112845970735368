import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import protocol from "../content/homepage/protocol.json"
import { StaticImage } from "gatsby-plugin-image"

export const Protocol = () => {
  return (
    <div className="base-section protocol-section">
      <StaticImage
        className="protocol-curve"
        src="../assets/img/layout/wave-3.svg"
        alt="curve"
      />
      <Container>
        <Row>
          <Col className="text-center">
            <p className="title">{protocol.title}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <StaticImage
              src="../assets/img/infographic.png"
              alt="infographic"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
