import * as React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import experts from "../content/homepage/experts.json"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const getImageFromData = (allImage, path) => {
  return getImage(
    allImage.filter(edge => edge.node.relativePath === path)[0].node
      .childImageSharp.gatsbyImageData
  )
}

export const Experts = ({ data }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "layout/map-2.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  const allImage = data.edges
  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
    >
      <div className="base-section expert-section">
        <Container>
          <Row>
            <Col className="text-center">
              <p className="title expert-section-title">{experts.title}</p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {experts.experts.map(e => {
              const image = getImageFromData(allImage, e.image)
              return (
                <Col xs={10} sm={8} md={5} lg={4} className="mb-5">
                  <Card className="text-center expert-card">
                    <Card.Body>
                      <GatsbyImage image={image} alt={e.alt} className="mb-3" />
                      <p className="expert-name">{e.name}</p>
                      <p className="expert-title">{e.title}</p>
                      <p className="expert-institution">{e.institution}</p>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </BackgroundImage>
  )
}
