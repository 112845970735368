import * as React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import hero from "../content/homepage/hero.json"

export const Hero = () => {
  return (
    <div className="base-section hero">
      <Container>
        <Row className="m-0 flex-column-reverse flex-lg-row text-center text-lg-left">
          <Col className="my-auto mx-auto mx-lg-0" xs={12} md={10} lg={7}>
            <h1 className="hero-title">{hero.title}</h1>
            <p className="slogan">{hero.slogan}</p>
            <p className="description">{hero.description}</p>
            <Button
              className="hero-button"
              href={hero.link}
              target="_blank"
              rel="noreferrer"
            >
              {hero.buttonTitle}
            </Button>
          </Col>
          <Col>
            <StaticImage src="../assets/img/hero.png" alt="hero" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
