import * as React from "react"
import { Button, Container, Navbar } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

export const NavBar = () => {
  return (
    <>
      <header>
        <Navbar>
          <Container>
            <Navbar.Brand>
              <StaticImage
                src="../assets/img/sd-logos/logo-color.png"
                alt="SmartDose.AI"
              />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Button className="outlined-button">SmartClinX</Button>
            </Navbar.Collapse>
          </Container>
          <StaticImage
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: "-1",
              maxWidth: "38rem",
            }}
            src="../assets/img/layout/diagonal-shape-1.svg"
            alt="shape"
          />
        </Navbar>
      </header>
    </>
  )
}
