import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import partners from "../content/homepage/partners.json"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const getImageFromData = (allImage, path) => {
  return getImage(
    allImage.filter(edge => edge.node.relativePath === path)[0].node
      .childImageSharp.gatsbyImageData
  )
}

export const Partners = ({ data }) => {
  const allImage = data.edges
  return (
    <div className="base-section partners-section">
      <Container>
        <Row>
          <Col className="text-center">
            <p className="title">{partners.title}</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {partners.partners.map(p => {
            const image = getImageFromData(allImage, p.image)
            return (
              <Col className="text-center" xs={12} lg={4}>
                <GatsbyImage alt={p.alt} image={image} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}
